module.exports = {
  number: {
    id: 'd0c476d6-05d9-4e59-8fef-5ef3ab180c00',
    translateCode: 'FORM.NUMBER'
  },
  workorderNumber: {
    id: '9b6a6b34-cbe6-4519-88ef-f30e56c4646f',
    translateCode: 'FORM.WORK_ORDER'
  },
  partSku: {
    id: '63d56bbe-17c0-4155-a1f6-775cee47a550',
    translateCode: 'FORM.SKU'
  },
  partRev: {
    id: '7bd52784-f684-423f-9419-a36b37320219',
    translateCode: 'FORM.REV'
  },
  serial: {
    id: 'b919e514-453a-4245-bdf8-a3a59a868cd5',
    translateCode: 'FORM.SERIAL'
  },
  stockSku: {
    id: 'f7e8e0e6-8b5e-4f3f-8e0e-68b5e4f3f8e0',
    translateCode: 'FORM.STOCK_SKU'
  },
  stockSerial: {
    id: 'e8e0e68b-5e4f-3f8e-0e68-b5e4f3f8e0e6',
    translateCode: 'FORM.STOCK_SERIAL'
  },
  stockLot: {
    id: '8e0e68b5-e4f3-f8e0-e68b-5e4f3f8e0e68',
    translateCode: 'FORM.STOCK_LOT_NUMBER'
  },
  workflowName: {
    id: 'ccf7fdf0-7091-46ed-8ee8-2fcd522da045',
    translateCode: 'FORM.WORKFLOW_NAME'
  },
  nodeName: {
    id: '0751836c-6a9b-482a-8fdc-387e02916856',
    translateCode: 'FORM.NODE_NAME'
  },
  accountName: {
    id: '2c75b88f-5915-4445-972d-c14860bc7bf7',
    translateCode: 'WO.CUSTOMER.ACCOUNT_NAME'
  },
  orderNumber: {
    id: '5413e512-a950-48b0-aecf-1ed07e9054e8',
    translateCode: 'WO.SALE_ORDER'
  },
  assignee: {
    id: 'cfaaccc4-88f3-4d70-8953-708be7ac0e41',
    translateCode: 'FORM.ASSIGNEE'
  },
  status: {
    id: 'c912a598-e563-4760-8858-2ca423a03170',
    translateCode: 'FORM.STATUS.NAME'
  },
  closedBy: {
    id: 'd7820a9d-cb8a-42df-9a07-8d19dbef9753',
    translateCode: 'FORM.CLOSED_BY'
  },
  closedAt: {
    id: 'eca33b57-e6c1-4c80-92be-6180ece01f99',
    translateCode: 'FORM.CLOSED_AT'
  },
  approvalWorkflowStatus: {
    id: '95491c67-441b-4a7f-b5d6-fef73a4e513c',
    translateCode: 'FORM.APPROVAL_STATUS'
  },
  ownerId: {
    id: '7e77fcfd-8aba-42ab-82f0-7fe0f6837cc4',
    translateCode: 'FORM.REPORTER'
  },
  created: {
    id: '41fab21f-be8f-4b6d-90ba-bf1d4db9aaf0',
    translateCode: 'CREATED_AT'
  },
  opened: {
    id: '1e0f5b0b-5b0b-5b0b-5b0b-5b0b5b0b5b0b',
    translateCode: 'FORM.OPENED'
  },
  daysOpen: {
    id: 'f2e0b5a9-5b9a-4b9e-9b0a-5b0b5b0b5b0b',
    translateCode: 'FORM.DAYS_OPEN'
  },
  formName: {
    id: 'dc6b93cb-753e-4eca-9862-2079b4962f2c',
    translateCode: 'FORM.NAME'
  },
  linkedTo: {
    id: '92d59b4e-137f-4d39-b37a-e93923f13a19',
    translateCode: 'FORM.LINKED_FORMS.LINKED_TO'
  },
  linkedFrom: {
    id: '07dc2100-1326-4ace-938f-88bf2f4231b8',
    translateCode: 'FORM.LINKED_FORMS.LINKED_FROM'
  }
}
