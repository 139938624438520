const _ = require('lodash')
const workflowVariablesDeclaration = require('../../client/src/app/modules/main/workflow/constants/variablesDeclaration')
const formVariablesDeclaration = require('../../client/src/app/modules/main/form/constants/variablesDeclaration')
const workorderVariablesDeclaration = require('../../client/src/app/modules/main/workorder/constants/variablesDeclaration')

const modelConversion = {
  Workflow: {
    property: { Workflow: 'workflow' },
    translate: 'WORKFLOW'
  },
  Workorder: {
    property: { Workflow: 'workorder' },
    translate: 'WORKORDER'
  },
  UserModel: {
    property: {
      Workflow: 'currentUser',
      Form: ['formCreator', 'formCloser', 'addressee'],
      Workorder: 'addressee'
    },
    translate: 'USER'
  },
  Revision: {
    property: { Workflow: 'unit' },
    translate: 'REVISION'
  }
}
const addCTypesToVariables = function addCTypesToVariables (
  modelsProperties,
  model
) {
  const propertiesByModel = _.pick(
    modelsProperties,
    'UserModel',
    'Workflow',
    'Revision',
    'Workorder'
  )
  Object.keys(propertiesByModel).forEach(modelName => {
    const cProperties = propertiesByModel[modelName].properties
      ? propertiesByModel[modelName].properties
      : propertiesByModel[modelName].definition.properties
    Object.keys(cProperties).forEach(key => {
      if (key.startsWith('C_')) {
        const translationKey = `${modelConversion[modelName].translate}.${key}`
        const type = (
          typeof cProperties[key].type !== 'function'
            ? cProperties[key].type
            : cProperties[key].type.name
        ).toLowerCase()
        const defaultValue = 'dummy'
        switch (model) {
          case 'Workflow':
            workflowVariablesDeclaration[
              modelConversion[modelName].property.Workflow
            ][key] = {
              translationKey,
              type,
              defaultValue
            }
            break
          case 'Form':
            if (modelName === 'UserModel') {
              modelConversion[modelName].property.Form.forEach(property => {
                formVariablesDeclaration[property][key] = {
                  translationKey,
                  type,
                  defaultValue
                }
              })
            }
            break
          case 'Workorder':
            if (modelName === 'UserModel') {
              workorderVariablesDeclaration[
                modelConversion[modelName].property.Workorder
              ][key] = {
                translationKey,
                type,
                defaultValue
              }
            }
            break
        }
      }
    })
  })

  switch (model) {
    case 'Workflow':
      return workflowVariablesDeclaration
    case 'Form':
      return formVariablesDeclaration
    case 'Workorder':
      return workorderVariablesDeclaration
  }
}

const getVariables = function getVariables (cProperties, model) {
  return addCTypesToVariables(cProperties, model)
}

module.exports = {
  getVariables
}
